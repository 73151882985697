import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import ThemeProvider from '../store/ThemeProvider';
import Layout from './components/Layout';
import HomePage from './containers/HomePage';
import AboutPage from './containers/AboutPage';
import Blog from './containers/Blog';
import BlogCMS from './containers/BlogCMS';
import PrivacyPolicy from './containers/PrivacyPolicy';
import MarqueeHome from './components/MarqueeHome'

import { setLocalStorage } from '../utils/common';
import './styles.scss';

const App = props => {
	useEffect(() => {
		const permittedDomains = [
			process.env.REACT_APP_IFRAME_BLOG_URL,
			process.env.REACT_APP_IFRAME_BASE_URL,
			'https://www.abhishektiwari.dev',
		];

		function onMessage(event) {
			try {
				if (permittedDomains.indexOf(event.origin) !== -1) {
					const payload = JSON.parse(event.data);
					if (payload.key && payload.key === 'storage') {
						setLocalStorage(payload.data.key, payload.data.value);
					}
				}
			} catch (error) { }
		}
		window.addEventListener('message', onMessage);

		return () => window.removeEventListener('message', onMessage);
	}, []);

	const content = (
		<>
			<iframe
				style={{ display: 'none' }}
				src={`${process.env.REACT_APP_IFRAME_BLOG_URL}/cross-domain`}
				id="ifr"
				sandbox="allow-scripts allow-same-origin"
			></iframe>
			<Helmet>
				<title>Abhishek Tiwari | Portfolio</title>
				<meta
					name="description"
					content="Abhishek Tiwari | Personal Portfolio | Personal Blog for Technology, Finance, Travel articles"
				/>
			</Helmet>
			<Switch>
				<Route exact path="/">
					<Layout to="home">
						<HomePage />
						<MarqueeHome />
						<AboutPage />
					</Layout>
				</Route>
				<Route exact path="/MyMovieList/privacy-policy">
					<PrivacyPolicy />
				</Route>
			</Switch>
		</>
	);
	const host = window.location.host;
	const arr = host.split('.').slice(0, host.includes('localhost') ? -1 : -2);

	// if (arr.length > 0) {
	// 	const subdomain = arr[0] === 'www' ? arr[1] : arr[0];

	// 	if (subdomain === 'blog') {
	// 		content = (
	// 			<>
	// 				<iframe
	// 					style={{ display: 'none' }}
	// 					src={`${process.env.REACT_APP_IFRAME_BASE_URL}/cross-domain`}
	// 					id="ifr"
	// 					sandbox="allow-scripts allow-same-origin"
	// 				></iframe>
	// 				<Helmet>
	// 					<title>Blog | Abhishek Tiwari</title>
	// 					<meta
	// 						name="description"
	// 						content="Blog | Abhishek Tiwari | Personal Blog for Technology, Finance, Travel articles"
	// 					/>
	// 				</Helmet>
	// 				<Layout to="blog">
	// 					<Blog />
	// 				</Layout>
	// 			</>
	// 		);
	// 	} else if (subdomain === 'admin') {
	// 		content = (
	// 			<>
	// 				<Helmet>
	// 					<title>Admin Panel</title>
	// 				</Helmet>
	// 				<BlogCMS />
	// 			</>
	// 		);
	// 	}
	// }
	return <ThemeProvider initialTheme={props.theme}>{content}</ThemeProvider>;
};

export default App;
