import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faGithub,
	faInstagram,
	faLinkedin,
	faGoodreads,
	faSpotify,
	faGoogle,
	faMedium,
	faSteam,
} from '@fortawesome/free-brands-svg-icons';
import './styles.scss';

const SocialHandles = (props) => {
	const gmailLink = 'mailto:abhishek.tiwari3507@gmail.com';
	const githubLink = 'https://github.com/abhishekti7';
	const linkedinLink = 'https://www.linkedin.com/in/abhishek-tiwari7/';
	const blogLink = process.env.REACT_APP_BLOG_URL;
	const instagramLink = 'https://www.instagram.com/abhiishekti/';
	const goodreadsLink = 'https://www.goodreads.com/thatsundayafternoon';
	const spotifyLink =
		'https://open.spotify.com/user/31qz6gf4ijxqq7vssmfk74qp57dm';
	const steamLink = 'https://steamcommunity.com/profiles/76561198416826157/';

	return (
		<div className="socials__container">
			<div className="socials__container--title">You can find me on</div>
			<div className="socials__container--links">
				<a href={gmailLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-gmail"
						icon={faGoogle}
						title="Gmail"
					/>
				</a>
				<a href={githubLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-github"
						icon={faGithub}
						title="GitHub"
					/>
				</a>
				<a href={linkedinLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-linkedin"
						icon={faLinkedin}
						title="LinkedIn"
					/>
				</a>
				{/* <a href={blogLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-blog"
						icon={faMedium}
						title="Blog"
					/>
				</a> */}
				<a href={instagramLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-instagram"
						icon={faInstagram}
						title="Instagram"
					/>
				</a>
				<a href={goodreadsLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-goodreads"
						icon={faGoodreads}
						title="Goodreads"
					/>
				</a>
				<a href={spotifyLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-spotify"
						icon={faSpotify}
						title="Spotify"
					/>
				</a>

				<a href={steamLink} target="_blank" rel="noreferrer">
					<FontAwesomeIcon
						className="icon icon-steam"
						icon={faSteam}
						title="Steam"
					/>
				</a>


			</div>
		</div>
	);
};

export default SocialHandles;
