import React, { useRef, useState } from 'react';
import { Link } from 'react-scroll';

import { useSpring, animated } from '@react-spring/web';

import ThemeSetter from '../ThemeSetter';
import useLostFocusListener from '../../../utils/useLostFocusListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const NavBar = (props) => {
	const navbarRef = useRef(null);
	const [navBarOpen, setNavBarOpen] = useState(false);

	const btnSprings = useSpring({
		transform: navBarOpen ? `rotate(360deg)` : `rotate(0deg)`
	});

	const springs = useSpring({
		width: navBarOpen ? '100%' : '0',
	})

	const onBarsClick = (event) => {
		setNavBarOpen(prevValue => !prevValue);
	};

	const onLostFocus = () => {
		setNavBarOpen(false);
	};

	useLostFocusListener(navbarRef, onLostFocus.bind(this));

	return (
		<div className="navbar__container">
			<div className="navbar__container--logo">
				<span>Abhishek Tiwari</span>
			</div>

			<div className="navbar__container--actions">
				<div className="normal">
					<ul>
						<li>
							<Link to="home">Home</Link>
						</li>
						{/* <li>
							<a href={process.env.REACT_APP_BLOG_URL}>Blog</a>
						</li> */}
						<li>
							<Link to="about" smooth={true} duration={400}>
								About
							</Link>
						</li>
						<li>
							<a
								href={`${process.env.REACT_APP_RESUME_NAME}`}
								target="_blank"
								rel="noreferrer"
							>
								Resume
							</a>
						</li>
						<li>
							<ThemeSetter />
						</li>
					</ul>
				</div>

				<div className="responsive">
					<div className="responsive__controls">
						<ThemeSetter />
						<FontAwesomeIcon
							className="icon-bars"
							icon={faBars}
							onClick={onBarsClick}
						/>
					</div>
					<animated.div
						ref={navbarRef}
						className="navbar__responsive"
						style={{
							...springs
						}}
					>
						<div className='responsive_right'>
							<div className="navbar__responsive--close">
								<animated.div style={{ ...btnSprings }} className="btn-close">
									<FontAwesomeIcon
										className="icon-cross"
										icon={faTimes}
										onClick={onBarsClick}
									/>
								</animated.div>
							</div>
							<ul>
								<li>
									<Link
										to="home"
										onSetActive={onLostFocus}
										onClick={onLostFocus}
									>
										Home
									</Link>
								</li>
								{/* <li>
									<a
										href={process.env.REACT_APP_BLOG_URL}
										onClick={onLostFocus}
									>
										Blog
									</a>
								</li> */}
								<li>
									<Link
										activeClass="active"
										to="about"
										smooth={true}
										duration={400}
										onClick={onLostFocus}
									>
										About
									</Link>
								</li>
								<li>
									<a
										href={`${process.env.REACT_APP_RESUME_NAME}`}
										target="_blank"
										rel="noreferrer"
										onClick={onLostFocus}
									>
										Resume
									</a>
								</li>
							</ul>
						</div>
					</animated.div>
				</div>
			</div>
		</div>
	);
};

export default NavBar;
